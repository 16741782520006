import gql from "graphql-tag";

import CLIO_INTEGRATION from "./clioIntegration";
import ORGANIZATION_INVITE from "./organizationInvite";
import ORGANIZATION_ISSUE from "./organizationIssue";
import ORGANIZATION_LOCATION from "./organizationLocation";
import ORGANIZATION_MEMBER from "./organizationMember";

const ORGANIZATION = gql`
  ${ORGANIZATION_MEMBER}
  ${ORGANIZATION_ISSUE}
  ${ORGANIZATION_LOCATION}
  ${ORGANIZATION_INVITE}
  ${CLIO_INTEGRATION}

  fragment ORGANIZATION on Organization {
    id
    svixAppPortalAccess
    zapierIntegrationId
    clioIntegration {
      ...CLIO_INTEGRATION
    }
    createdBy {
      id
    }
    description
    name
    members {
      ...ORGANIZATION_MEMBER
    }
    issues {
      ...ORGANIZATION_ISSUE
    }
    invites {
      ...ORGANIZATION_INVITE
    }
    locations {
      ...ORGANIZATION_LOCATION
    }
    type
    clientsShared
    updatedAt
    createdAt
    clients {
      id
    }
    logo
    subscriptionType

    dataExtractorEnabled
    displayCentsDigits
    primaryColor
    backgroundColor
    formUrn
    intakeSubmittedTitle
    intakeSubmittedMessage
    intakeFormHeader
    intakeFormFooter
    intakesEnabled
    intakeFormLogo
    automaticInterviewsEnabled
    automaticInterviewsText
  }
`;

export default ORGANIZATION;
