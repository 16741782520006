/* eslint-disable react/no-array-index-key */
import React from "react";

import { Col, Row } from "reactstrap";

import { formInputs } from "components/ClientProfile/forms/counsel/counselFormHelpers";
import { provinceOptions } from "components/calculations/utils/regionNames";
import SelectField from "components/common/inputs/Select/SelectField";
import TextInput from "components/common/inputs/TextInput";

const CounselFormInputs = ({ clientLoading, createLoading, updateLoading }) => {
  return (
    <React.Fragment>
      {formInputs?.map((input, index) => {
        if (Array.isArray(input)) {
          return (
            <Row key={index}>
              {input.map((inputEl) => (
                <Col key={inputEl.name} xs={6}>
                  <TextInput
                    {...inputEl}
                    disabled={clientLoading || createLoading || updateLoading}
                  />
                </Col>
              ))}
            </Row>
          );
        }
        if (input.name === "location.residence") {
          return (
            <Row key="location.residence">
              <Col>
                <SelectField
                  {...input}
                  disabled={clientLoading || createLoading || updateLoading}
                  options={provinceOptions.filter((p) => p.value !== "CDN")}
                />
              </Col>
            </Row>
          );
        }
        return (
          <Row key={input.name}>
            <Col>
              <TextInput {...input} disabled={clientLoading || createLoading || updateLoading} />
            </Col>
          </Row>
        );
      })}
    </React.Fragment>
  );
};

export default CounselFormInputs;
