import { useEffect, useRef, useState } from "react";

export const MAX_REGENERATE_SUBSCRIPTION_COUNT = 50;

/**
 *  User could not have subscription yet after purchasing it,
 * so we're trying to refetch it until we get it
 */
const useRegenerateSubscription = ({ me, refetch }) => {
  const [refetchTimer, setRefetchTimer] = useState(1000);
  const [refetchCounter, setRefetchCounter] = useState(0);

  const timeoutRef = useRef();

  useEffect(() => {
    if (me?.id && !me?.subscription) {
      timeoutRef.current = setTimeout(() => {
        refetch();

        setRefetchCounter((prev) => {
          if (prev > MAX_REGENERATE_SUBSCRIPTION_COUNT) clearTimeout(timeoutRef.current);
          return prev + 1;
        });

        setRefetchTimer((prev) => prev * 2);
      }, refetchTimer);

      return () => clearTimeout(timeoutRef.current);
    }
  }, [me, refetch, refetchTimer]);

  return [refetchCounter, setRefetchCounter];
};

export default useRegenerateSubscription;
