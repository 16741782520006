/* eslint-disable no-console */
import { useCallback } from "react";

import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import CLIENT_FRAGMENT from "graphql/fragments/client";
import ALL_LAWYERS from "graphql/sanity/allLawyers";
import UPDATE_LAWYER from "graphql/sanity/mutations/updateLawyer";

import getVariablesForAllLawyersQuery from "utils/lawyers/getVariablesForAllLawyersQuery";

/**
 * Hook: useUpdateLawyer
 *
 * @param {Object} client - The client data.
 * @param {String} residence - The province shorthand for the client's location.
 *
 * When a lawyer update is triggered, we now refetch our ALL_LAWYERS query immediately
 * so that the shared baseline in the Counsel form is refreshed.
 */
const useUpdateLawyer = (client, residence) => {
  const [mutate, { loading, error, data }] = useMutation(UPDATE_LAWYER);

  const handleUpdateLawyer = useCallback(
    async (values, isShowToast = true) => {
      const { location = {}, sanityId, ...restValues } = values;

      let toastId = null;
      if (isShowToast) {
        toastId = toast.loading("Updating Lawyer...", { autoClose: false });
      }

      try {
        await mutate({
          variables: {
            updateSanity: true,
            where: {
              clientId: client?.id,
            },
            data: {
              ...restValues,
              location,
            },
          },
          refetchQueries: [
            {
              query: ALL_LAWYERS,
              variables: getVariablesForAllLawyersQuery({ residence }),
            },
          ],
          awaitRefetchQueries: true,
          update: (cache, { data }) => {
            cache.writeFragment({
              id: `Client:${client?.id}`,
              fragment: CLIENT_FRAGMENT,
              fragmentName: "CLIENT_FRAGMENT",
              data: {
                ...client,
                exLawyer: {
                  ...data.updateLawyer,
                },
              },
            });
          },
        });

        if (isShowToast) {
          toast.dismiss(toastId);
          toast.success("Updated Lawyer", { autoClose: 3000 });
        }
      } catch (err) {
        if (isShowToast) {
          toast.dismiss(toastId);
          toast.error("Error updating Lawyer", { autoClose: 3000 });
        }
        console.error(err);
      }
    },
    [client, mutate, residence],
  );

  return [handleUpdateLawyer, { loading, error, data }];
};

export default useUpdateLawyer;
