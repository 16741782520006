import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useQuery } from "@apollo/client";
import classNames from "classnames";
import { FormikProvider, useFormik } from "formik";
import { capitalize, sortBy } from "lodash";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Col, Form, Row } from "reactstrap";

import useCreateDocument from "graphql/hooks/documents/useCreateDocument";
import ALL_COURTS from "graphql/sanity/allCourts";
import ALL_JURISDICTIONS from "graphql/sanity/allJurisdictions";
import ALL_TEMPLATES from "graphql/sanity/allTemplates";

import { regionsKeys } from "components/calculations/utils/regionNames";
import Button from "components/common/Button";
import Loading from "components/common/Loading";
import SelectField from "components/common/inputs/Select/SelectField";
import TaxYearSelect from "components/common/inputs/Select/TaxYearSelect";
import TextInput from "components/common/inputs/TextInput";

import useCourtInformation from "hooks/me/useCourtInformation";
import useCreateDocumentFlow from "hooks/useCreateDocumentFlow";
import useModal from "hooks/useModal";

import { getOptions } from "helpers/documentForm";

import { formatDate } from "utils/date";
import getVariablesForJurisdictionQuery from "utils/jurisdiction/getVariablesForJurisdictionQuery";
import getVariablesForAllTemplatesQuery from "utils/templates/getVariablesForAllTemplatesQuery";

const CreateDocumentForm = ({ setJurisdiction, isShowJurisdiction, setIsShowJurisdiction }) => {
  const { options, close } = useModal("CREATE_DOCUMENT");
  const { open } = useModal("CLIENT_DOCUMENT_SETTINGS");

  const [document, setDocument] = useState(null);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [isManageSections, setIsManageSections] = useState(false);
  const [taxYear, setTaxYear] = useState(new Date().getFullYear().toString());

  const history = useHistory();
  const searchInputRef = useRef();

  const { client } = options || {};

  const { provinceShorthand: residence, courtId, courtFile } = useCourtInformation(client);

  const documentTitle = document?.name?.replace(/(.*): /, "");

  const clientType = useMemo(() => {
    const isPlaintiff = client?.isPlaintiff ? "Plaintiff" : "Defendant";
    const isApplicant = client?.isApplicant ? "Applicant" : "Respondent";
    const clientLabel = client?.clientLabel;

    return `${capitalize(clientLabel)} is ${isPlaintiff}/${isApplicant}`;
  }, [client]);

  /* ===================
    Jurisdictions
    ================== */
  const { data, loading: jurisdictionLoading } = useQuery(ALL_JURISDICTIONS, {
    variables: getVariablesForJurisdictionQuery(),
  });

  const allJurisdictions = useMemo(() => {
    const jurisdictions = [];

    if (data?.allCountry) {
      jurisdictions.push(...data.allCountry);
    }

    if (data?.allProvince) {
      jurisdictions.push(...data.allProvince);
    }

    return jurisdictions;
  }, [data]);

  const defaultJurisdictionShorthand = useMemo(() => {
    const jurisdiction = options?.defaultJurisdiction || residence;

    const existingJurisdiction = allJurisdictions?.find(
      (jurisdictionEl) => jurisdiction === jurisdictionEl?.shorthand,
    );

    return existingJurisdiction?.shorthand;
  }, [allJurisdictions, options?.defaultJurisdiction, residence]);

  /* ===============
    Form Submit
    ============= */
  const [createDocument, { loading }] = useCreateDocument({ client });

  const createDocumentFlow = useCreateDocumentFlow(0, { client });

  const handleCreateDocument = useCallback(
    async (sections) => {
      if (loading) return null;

      const toastId = toast.loading("Creating new document...");

      try {
        setDocumentLoading(true);

        await createDocument({
          variables: {
            data: {
              templateId: document?._id,
              clientId: client?.id,
              taxYear: +taxYear,
              sections: sections?.length ? sections : undefined,
            },
          },
        });

        if (options?.shouldNavigateToClient) {
          const clientName = `${client?.profile?.firstName} ${client?.profile?.lastName}`;

          history.push(`/?clientId=${client?.id}&tab=documents&clientSearch=${clientName}`);
        }

        options?.onDocumentCreate?.();

        toast.success("Document successfully created!");
      } catch (err) {
        console.error(err);
        toast.error("Couldn't create document");
      } finally {
        toast.dismiss(toastId);
        setDocumentLoading(false);
        close();
      }
    },
    [
      client?.id,
      client?.profile?.firstName,
      client?.profile?.lastName,
      close,
      createDocument,
      document?._id,
      history,
      loading,
      options,
      taxYear,
    ],
  );

  const onSubmit = useCallback(async () => {
    createDocumentFlow({
      client,
      document,
      submit: handleCreateDocument,
      isManageSections,
    });

    close();
  }, [client, document, createDocumentFlow, handleCreateDocument, close, isManageSections]);

  /* ===============
    Initialize Form
    =========== */
  const formik = useFormik({
    initialValues: {
      jurisdiction: defaultJurisdictionShorthand || "",
      document: "",
      court: courtId || "ANY",
      search: options?.defaultSearch || "",
    },
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const { setFieldValue, values } = formik;
  const { jurisdiction, court, search } = values;

  /* =================
    Courts
    ============= */
  const { data: courtsData, loading: courtsLoading } = useQuery(ALL_COURTS, {
    variables: { where: { province: { shorthand: { eq: jurisdiction } } } },
    skip: !jurisdiction,
  });

  const courtLocation = useMemo(
    () =>
      courtsData?.allCourt
        ?.find((court) => court?._id === courtId)
        ?.locations?.find(({ _id }) => _id === client?.court?.courtLocationId)?.name || "",
    [courtsData?.allCourt, courtId, client?.court?.courtLocationId],
  );

  /* =================
    Templates
    ============= */
  const { loading: templateLoading, data: dataTemplates } = useQuery(ALL_TEMPLATES, {
    variables: getVariablesForAllTemplatesQuery({
      category: options.defaultCategory,
      courts: courtsData?.allCourt,
    }),
  });

  const filteredTemplates = useMemo(() => {
    return dataTemplates?.allDocumentTemplate?.filter((template) => {
      const templateCategoryName = template?.category?.name || "";

      const isCourtExist =
        court === "ANY" ||
        template?.courts?.find((tCourt) => tCourt._id === court) ||
        template?.court?._id === court;

      const isInJurisdiction = template?.jurisdiction?.shorthand === jurisdiction;

      const isInCategory = templateCategoryName.includes(options?.defaultCategory || "");

      if (isInJurisdiction && isCourtExist && isInCategory) {
        const templateName = template?.name?.toLowerCase() || "";
        const templateNumber = template?.number?.toLowerCase() || "";
        const templateDescription = template?.description?.toLowerCase() || "";
        const searchValue = search.toLowerCase();

        return (
          templateName.includes(searchValue) ||
          templateNumber.includes(searchValue) ||
          templateDescription.includes(searchValue)
        );
      }

      return false;
    });
  }, [dataTemplates?.allDocumentTemplate, options?.defaultCategory, jurisdiction, court, search]);

  const sortedTemplates = useMemo(() => {
    return filteredTemplates
      ?.slice()
      .sort((a, b) => a?.number?.localeCompare(b?.number, "en", { numeric: true }));
  }, [filteredTemplates]);

  const emptyTemplatesInfo = useMemo(() => {
    if (sortedTemplates?.length) {
      setIsShowJurisdiction(false);
      return null;
    }

    if (templateLoading || courtsLoading) return <Loading />;

    if (!jurisdiction && !court) {
      setIsShowJurisdiction(true);
      return <span className="no-templates">Select jurisdiction</span>;
    }

    if (jurisdiction && !court) {
      return <span className="no-templates">Select court</span>;
    }

    if (templateLoading === false && !sortedTemplates?.length) {
      return <span className="no-templates">More forms and precedents are coming soon...</span>;
    }
  }, [
    sortedTemplates?.length,
    templateLoading,
    courtsLoading,
    jurisdiction,
    court,
    setIsShowJurisdiction,
  ]);

  /* ==================
    Select Options
    ================= */
  const jurisdictionOptions = useMemo(() => {
    return sortBy(
      allJurisdictions.map((jurisdiction) => ({
        label: jurisdiction.name,
        value: jurisdiction.shorthand,
      })),
      "label",
    );
  }, [allJurisdictions]);

  const courtOptions = useMemo(() => {
    return [
      { value: "ANY", label: "Any Court" },
      ...(sortBy(getOptions(courtsData?.allCourt), "label") || []),
    ];
  }, [courtsData?.allCourt]);

  const courtLocationLabel = `${courtLocation || ""}${courtLocation && courtFile ? " – " : ""}${
    courtFile || ""
  }`;

  useEffect(() => {
    // Set Jurisdiction for Modal's header
    setJurisdiction(regionsKeys[jurisdiction]);

    const existingDefaultCourt = courtsData?.allCourt?.find((court) => court._id === courtId);

    setFieldValue("court", existingDefaultCourt?._id || "ANY");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jurisdiction, courtsData, courtId]);

  const filteredSections = document?.sections?.filter(Boolean);

  useEffect(() => {
    setIsManageSections(!!filteredSections?.length);
  }, [filteredSections]);

  useEffect(() => {
    if (sortedTemplates?.length && !sortedTemplates?.find((f) => f._id === document?._id)) {
      setDocument(sortedTemplates[0]);
    }

    if (!sortedTemplates?.length) {
      setDocument(null);
    }
  }, [sortedTemplates, document, templateLoading]);

  useEffect(() => {
    if (!jurisdiction && !defaultJurisdictionShorthand) {
      setIsShowJurisdiction(true);
    }
  }, [jurisdiction, defaultJurisdictionShorthand, setIsShowJurisdiction]);

  useEffect(() => {
    searchInputRef.current?.focus();
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form className="create-document-form">
        <Row>
          <Col sm={7}>
            <Row>
              {isShowJurisdiction && (
                <Col sm={6} className="pr-sm-2">
                  <SelectField
                    placeholder="Select Jurisdiction"
                    options={jurisdictionOptions}
                    containerClassName="mb-2 mb-sm-3"
                    name="jurisdiction"
                    label="Jurisdiction"
                    isLoading={jurisdictionLoading}
                    isRequired
                  />
                </Col>
              )}
              <Col
                sm={isShowJurisdiction ? 6 : 12}
                className={classNames({ "pl-sm-2": isShowJurisdiction })}
              >
                <SelectField
                  placeholder="Select Court"
                  options={courtOptions}
                  containerClassName="mb-3"
                  value={court}
                  name="court"
                  label="Court"
                  isLoading={courtsLoading}
                  isRequired
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="pr-sm-2">
                <TextInput
                  inputRef={searchInputRef}
                  containerClassName="mb-2 mb-sm-3"
                  name="search"
                  label="Search"
                  placeholder="Affidavit"
                  autoFocus
                />
              </Col>
              <Col sm={6} className="pl-sm-2 mb-3">
                <TaxYearSelect
                  label="Tax Year"
                  value={taxYear}
                  onChange={({ value }) => setTaxYear(value)}
                />
              </Col>
            </Row>
            <div
              className={classNames("templates-selector", {
                loading: templateLoading,
                empty: !templateLoading && !sortedTemplates?.length,
              })}
            >
              {emptyTemplatesInfo}
              {!templateLoading && !!sortedTemplates?.length && (
                <Fragment>
                  <div className="templates-selector-list">
                    {sortedTemplates?.map((template) => (
                      <div
                        className={classNames("template", {
                          active: template._id === document?._id,
                        })}
                        onClick={() => setDocument(template)}
                        key={template._id}
                      >
                        <div className="template-name">
                          {`${template.name}${template.deskdivorce ? " (Desk Application)" : ""}`}
                        </div>
                        <div className="template-number">{template.number}</div>
                      </div>
                    ))}
                  </div>
                  <div className="templates-selector-footer">
                    {sortedTemplates?.length} Documents
                  </div>
                </Fragment>
              )}
            </div>
          </Col>
          <Col sm={5} className="document-column">
            {document?._id && !!sortedTemplates?.length ? (
              <div className="document-description-content">
                <h4 className="document-title">
                  {document?.number ? `${document?.number}: ` : ""}
                  {documentTitle}
                </h4>
                <span className="document-subtitle">{document?.court?.name}</span>
                <span className="document-subtitle">{courtLocationLabel}</span>
                <span className="document-subtitle">{clientType}</span>
                <span
                  className="change-settings"
                  onClick={() => open({ client, jurisdiction, courtId: courtId || court })}
                >
                  Change Settings
                </span>
                {!!filteredSections?.length && (
                  <div className="manage-sections custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      name="manage-sections"
                      id="manage-sections"
                      onChange={() => setIsManageSections(!isManageSections)}
                      type="checkbox"
                      checked={isManageSections}
                    />
                    <label className="custom-control-label" htmlFor="manage-sections">
                      <span>Manage Sections</span>
                    </label>
                  </div>
                )}
                <div className="divider" />
                <p className="description">Description:</p>
                <p className="description-content">{document?.description || "No Description"}</p>
                {document?.official_publication_date && (
                  <p className="revision-date">
                    <span className="date-label">Official Revision Date:</span>
                    <span className="date-value">
                      {formatDate({
                        date: document?.official_publication_date,
                        template: "MM.DD.YYYY",
                      })}
                    </span>
                  </p>
                )}
                {document?.official_source_url && (
                  <a
                    href={document?.official_source_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="document-link"
                  >
                    Link to official source
                  </a>
                )}
                <a
                  href="https://www.divorcepath.com/help/court-forms-precedents"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="document-link help-link"
                >
                  Help with Forms
                </a>
              </div>
            ) : (
              <div className="no-document">More forms coming soon</div>
            )}
            <div className="buttons d-flex">
              <Button disabled={!document?._id || documentLoading} onClick={onSubmit}>
                {documentLoading ? "Creating Document..." : "Create"}
              </Button>
              <Button color="secondary" onClick={close}>
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </FormikProvider>
  );
};

export default CreateDocumentForm;
