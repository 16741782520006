/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState, useEffect, useMemo } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { FormikProvider, useFormik } from "formik";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import { toast } from "react-toastify";
import { Form } from "reactstrap";
import * as yup from "yup";

import useCreateLawyer from "graphql/hooks/lawyer/useCreateLawyer";
import useUpdateLawyer from "graphql/hooks/lawyer/useUpdateLawyer";
import UPDATE_CLIENT from "graphql/mutations/clients/updateClient";
import ALL_LAWYERS from "graphql/sanity/allLawyers";

import AutoSave from "pages/profile/AutoSave";

import CounselFormInputs from "components/ClientProfile/forms/counsel/CounselFormInputs";
import CreateLawyerSelect from "components/ClientProfile/forms/counsel/CreateLawyerSelect";
import SubmitChangesCard from "components/ClientProfile/forms/counsel/SubmitChangesCard";
import {
  getInitialValues,
  computeUpdatedLawyer,
  normalizeLawyerData,
} from "components/ClientProfile/forms/counsel/counselFormHelpers";
import difference from "components/calculations/utils/difference";
import Button from "components/common/Button";
import ToggleButtons from "components/common/ToggleButtons";

import useCourtInformation from "hooks/me/useCourtInformation";

import assignObjectValuesToSet from "utils/assignObjectValuesToSet";
import getVariablesForAllLawyersQuery from "utils/lawyers/getVariablesForAllLawyersQuery";

const validationSchema = yup.object().shape({
  location: yup.object().shape({
    email: yup.string().email("Invalid email address"),
  }),
});

const Counsel = ({ user, onSubmit }) => {
  const { provinceShorthand: residence } = useCourtInformation(user?.client);

  const { data, refetch } = useQuery(ALL_LAWYERS, {
    variables: getVariablesForAllLawyersQuery({ residence }),
  });

  // Extract the lawyer associated with the client (from the shared database)
  const exLawyer = useMemo(() => {
    if (!user?.client?.exLawyer) return null;
    return {
      ...user?.client?.exLawyer,
      sanityId: user?.client?.exLawyer?.sanityId || "non-sanity",
    };
  }, [user?.client?.exLawyer]);

  /* 
    Maintain two copies:
    - clientLawyerData: used for autosave and drives the form's UI.
    - sharedLawyerBaseline: a frozen snapshot of the shared lawyer data used for diff comparison.
  */
  const [clientLawyerData, setClientLawyerData] = useState(exLawyer);
  // Initialize baseline as null; we will update it using the shared lawyer DB info.
  const [sharedLawyerBaseline, setSharedLawyerBaseline] = useState(null);

  // Update the shared baseline using the matching lawyer data from the shared database.
  useEffect(() => {
    if (data?.allLawyer && user?.client?.exLawyer) {
      // Look up the matching lawyer from ALL_LAWYERS
      const sharedLawyer = data.allLawyer.find(
        (lawyer) =>
          lawyer?._id === user.client.exLawyer.sanityId || lawyer?._id === user.client.exLawyer.id,
      );
      if (sharedLawyer) {
        setSharedLawyerBaseline(cloneDeep(sharedLawyer));
      }
    }
  }, [data?.allLawyer, user?.client?.exLawyer]);

  // When autosave updates the client (user.client.exLawyer), update only clientLawyerData.
  useEffect(() => {
    if (user?.client?.exLawyer) {
      const newClientData = cloneDeep(user.client.exLawyer);
      setClientLawyerData(newClientData);
    }
  }, [user?.client?.exLawyer]);

  const computeInitialValues = useCallback(
    (lawyer) => ({
      ...getInitialValues(lawyer),
      isSelfRepresented: lawyer ? false : user?.client?.exProfile?.isSelfRepresented ?? true,
    }),
    [user?.client?.exProfile?.isSelfRepresented],
  );

  const [initialValues, setInitialValues] = useState(computeInitialValues(clientLawyerData));

  const [updateClient, { loading: clientLoading }] = useMutation(UPDATE_CLIENT);
  const [createLawyer, { loading: createLawyerLoading }] = useCreateLawyer(user?.client);
  const [updateLawyer, { loading: updateLoading }] = useUpdateLawyer(user?.client, residence);

  const createLoading = createLawyerLoading || updateLoading;

  const handleSubmitForm = useCallback(
    async (values, { setSubmitting }) => {
      const sharedBaselineValues = getInitialValues(user?.client?.exLawyer);
      const differenceData = difference(values, {
        ...sharedBaselineValues,
        isSelfRepresented: user?.client?.exProfile?.isSelfRepresented,
      });

      try {
        if (!isEmpty(differenceData)) {
          setSubmitting(true);
          const { location, name, orgName, sanityId, isSelfRepresented } = values;
          const variables = {
            where: { id: user?.client?.id },
            data: {
              exProfile: {
                upsert: {
                  update: {
                    isSelfRepresented: { set: isSelfRepresented },
                  },
                  create: {
                    isSelfRepresented,
                  },
                },
              },
            },
          };

          if (isSelfRepresented) {
            if (user.client.exLawyer?.id) {
              variables.data.exLawyer = {
                disconnect: { id: { equals: user.client.exLawyer.id } },
              };
            }
          } else {
            const locationData = {
              upsert: {
                create: location,
                update: assignObjectValuesToSet(location),
              },
            };
            const exLawyerData = { name, orgName, sanityId };
            variables.data.exLawyer = {
              upsert: {
                create: {
                  ...exLawyerData,
                  location: { create: location },
                },
                update: assignObjectValuesToSet({
                  ...exLawyerData,
                  location: locationData,
                }),
              },
            };
          }

          await updateClient({ variables });

          /*
            Removed the message for createDocumentFlow,
            because it made the following message 'Creating new document...' not disappear
          */
          if (!onSubmit) {
            toast.success(
              isSelfRepresented
                ? "Opposing counsel has been unlinked."
                : "Opposing counsel information has been successfully changed",
            );
          }
        }
      } catch (err) {
        console.error("Error in handleSubmitForm", err);
        err?.graphQLErrors?.forEach(({ message }) => toast.error(message));
      } finally {
        setSubmitting(false);
        onSubmit?.();
      }
    },
    [updateClient, user?.client, onSubmit],
  );

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmitForm,
    validationSchema,
    validateOnBlur: false,
  });

  const { handleSubmit, setFieldValue, values, dirty } = formik;

  // Compute whether any lawyer data in the form differs from the shared baseline.
  const hasLawyerChanges = useMemo(() => {
    if (!sharedLawyerBaseline) return false;
    const computed = computeUpdatedLawyer(values);
    const baseline = normalizeLawyerData(sharedLawyerBaseline);

    return !isEqual(computed, baseline);
  }, [values, sharedLawyerBaseline]);

  // Auto-reset effect: update the form values from autosaved clientLawyerData only if there are NO manual changes.
  useEffect(() => {
    const newValues = computeInitialValues(clientLawyerData);
    if (!dirty && !hasLawyerChanges && !isEqual(values, newValues)) {
      setInitialValues(newValues);
      formik.setValues(newValues);
    }
  }, [clientLawyerData, dirty, computeInitialValues, values, formik, hasLawyerChanges]);

  const handleChangeIsSelfRepresented = useCallback(
    (value) => {
      setFieldValue("isSelfRepresented", value);
      if (value === true) {
        setFieldValue("sanityId", "");
        setFieldValue("name", "");
        setFieldValue("orgName", "");
        setFieldValue("location", {
          email: "",
          city: "",
          phone: "",
          fax: "",
          postal: "",
          street1: "",
          street2: "",
          country: "",
          residence: "",
        });
      }
    },
    [setFieldValue],
  );

  return (
    <FormikProvider value={formik}>
      {!onSubmit && <AutoSave />}
      <Form className="counsel-form" onSubmit={handleSubmit}>
        <ToggleButtons
          label="Is the Opposing Party Self-Represented?"
          name="isSelfRepresented"
          value={values.isSelfRepresented}
          buttons={[
            { value: true, label: "Self-Represented" },
            { value: false, label: "Has Counsel" },
          ]}
          className="mt-3"
          onChange={handleChangeIsSelfRepresented}
        />

        {!values.isSelfRepresented && (
          <React.Fragment>
            <CreateLawyerSelect
              user={user}
              refetch={refetch}
              clientLawyerData={clientLawyerData}
              data={data}
              setClientLawyerData={setClientLawyerData}
              setSharedLawyerBaseline={setSharedLawyerBaseline}
              computeInitialValues={computeInitialValues}
              createLawyer={createLawyer}
              updateLawyer={updateLawyer}
              clientLoading={clientLoading}
              createLoading={createLoading}
              updateLoading={updateLoading}
            />

            <CounselFormInputs
              clientLoading={clientLoading}
              createLoading={createLoading}
              updateLoading={updateLoading}
            />
          </React.Fragment>
        )}

        <SubmitChangesCard
          clientLawyerData={clientLawyerData}
          setClientLawyerData={setClientLawyerData}
          setSharedLawyerBaseline={setSharedLawyerBaseline}
          computeUpdatedLawyer={computeUpdatedLawyer}
          computeInitialValues={computeInitialValues}
          hasLawyerChanges={hasLawyerChanges}
          updateLawyer={updateLawyer}
          createLawyer={createLawyer}
          updateLoading={updateLoading}
        />

        {onSubmit && (
          <div className="d-flex justify-content-between align-items-end buttons">
            <Button
              type="submit"
              size="lg"
              leftFAIcon="check"
              disabled={clientLoading || createLoading || updateLoading}
            >
              Save & Submit
            </Button>
          </div>
        )}
      </Form>
    </FormikProvider>
  );
};

export default Counsel;
