import dayjs from "dayjs";

const getEarliestDate = (dates) => {
  return (
    dates
      .filter((date) => date)
      .map((date) => dayjs(date))
      .sort((a, b) => a - b)[0]
      ?.toISOString() || null
  );
};

export default getEarliestDate;
