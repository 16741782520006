const computeUpdatedLawyer = (vals) => ({
  _id: vals.sanityId,
  name: vals.name,
  orgName: vals.orgName,
  fax: vals.location?.fax || null,
  email: vals.location?.email || "",
  phone: vals.location?.phone || "",
  postal: vals.location?.postal || "",
  city: vals.location?.city || "",
  residence: vals.location?.residence
    ? typeof vals.location.residence === "object"
      ? { shorthand: vals.location.residence.shorthand }
      : { shorthand: vals.location.residence }
    : null,
  country: vals.location?.country || "",
  street1: vals.location?.street1 || "",
  street2: vals.location?.street2 || "",
});

export default computeUpdatedLawyer;
