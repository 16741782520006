import React, { useEffect, useMemo, useState } from "react";

import { useQuery } from "@apollo/client";
import { useFormik, FormikProvider } from "formik";
import { omit, sortBy, uniqBy } from "lodash";
import { Col, Form, Row } from "reactstrap";

import useCreateOrganizationLocation from "graphql/hooks/organization/location/useCreateOrganizationLocation";
import useUpdateOrganizationLocation from "graphql/hooks/organization/location/useUpdateOrganizationLocation";
import ALL_CITY from "graphql/sanity/allCity";

import { provinceOptions } from "components/calculations/utils/regionNames";
import Button from "components/common/Button";
import HttpInput from "components/common/inputs/HttpInput";
import CourtLocationSelect from "components/common/inputs/Select/CourtLocationSelect";
import CourtSelect from "components/common/inputs/Select/CourtSelect";
import CreatableSelect from "components/common/inputs/Select/CreatableSelect";
import Select from "components/common/inputs/Select/SelectField";
import TextInput from "components/common/inputs/TextInput";

import useModal from "hooks/useModal";

import { defaultValues, formInputs, validationSchema } from "helpers/locationForm";

export const getOptions = (data) => {
  return (data || []).map((item) => ({
    id: item._id,
    value: item._id,
    label: item.name,
  }));
};

const LocationForm = ({ formId, type, defaultLocation, onSubmit }) => {
  const {
    options: { location, remove } = { location: defaultLocation },
    close,
    setLoading,
  } = useModal(type === "CREATE" ? "CREATE_LOCATION" : "EDIT_LOCATION");

  /* =====================
    Initial Values */
  const initialValues = useMemo(() => {
    const parsedValues = location ? omit(location, ["__typename", "id"]) : defaultValues;

    if (parsedValues.city) {
      parsedValues.city = {
        value: parsedValues.city,
        label: parsedValues.city,
      };
    } else {
      parsedValues.city = null;
    }

    return parsedValues;
  }, [location]);

  const [regionShorthand, setRegionShorthand] = useState(initialValues.residence);

  /* =================================
    Create/Edit Location on submit */
  const [createOrganizationLocation, { loading: createLoading }] = useCreateOrganizationLocation();
  const [updateOrganizationLocation, { loading: updateLoading }] =
    useUpdateOrganizationLocation(location);

  const handleSubmitForm = async (values) => {
    const preparedValues = { ...values, city: values.city?.value || null };

    if (type === "CREATE") {
      await createOrganizationLocation(preparedValues);
    } else {
      await updateOrganizationLocation(preparedValues);
    }

    onSubmit?.();
    close();
  };

  const loadingUpdateOrg = createLoading || updateLoading;

  /* =======================
    Initialize Form */
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: false,
    initialValues,
    validationSchema,
    onSubmit: handleSubmitForm,
  });

  const { handleSubmit, setFieldValue, values } = formik;

  const { residence, courtId } = values;

  /* ====================
    Provinces Data 
    
    - Hide Canada(CDN) option for locations,
    but show it if has been selected before */
  const currentProvinceOptions = useMemo(() => {
    const copiedOptions = [...provinceOptions];
    const canadaOptionIndex = provinceOptions.findIndex((o) => o.value === "CDN");

    if (canadaOptionIndex !== -1) {
      if (location?.data?.residence === "CDN") {
        copiedOptions[canadaOptionIndex].isDisabled = true;
      } else {
        copiedOptions.splice(canadaOptionIndex, 1);
      }
    }

    return copiedOptions;
  }, [location?.data?.residence]);

  /* =================
    Cities Data */
  const { data: { allCity = [] } = {}, loading: loadingCities } = useQuery(ALL_CITY, {
    variables: { where: { province: { shorthand: { eq: regionShorthand } } } },
  });

  const cities = useMemo(() => {
    const allCityOptions = allCity.map(({ name, _id }) => ({
      value: name,
      label: name,
      id: _id,
    }));

    const uniqCityOptions = uniqBy(
      [...allCityOptions, ...(initialValues.city ? [initialValues.city] : [])],
      "label",
    );

    return sortBy(uniqCityOptions, "label");
  }, [allCity, initialValues.city]);

  /* Set loading for the parent "Modal" component for disabling "CREATE" buttons */
  useEffect(() => {
    setLoading(loadingUpdateOrg);
  }, [setLoading, loadingUpdateOrg]);

  return (
    <FormikProvider value={formik}>
      <Form id={formId} onSubmit={handleSubmit} className="create-location-form">
        <div className="location-col d-flex justify-content-between">
          <div key="residence" className="residence w-100 mr-3">
            <Select
              name="residence"
              options={currentProvinceOptions}
              containerClassName="mb-3"
              placeholder="Province"
              onChange={({ value }) => {
                setRegionShorthand(value);
                setFieldValue("residence", value);
                setFieldValue("city", null);
                setFieldValue("courtId", null);
                setFieldValue("courtLocationId", null);
              }}
            />
          </div>
          <div key="city" className="city w-100">
            <CreatableSelect
              name="city"
              containerClassName="mb-3"
              placeholder={loadingCities ? "Loading..." : "City *"}
              disabled={loadingCities}
              options={cities}
              handleChange={(option) => {
                setFieldValue("city", option);
              }}
              formatCreateLabel={(newOptionName) => `Choose "${newOptionName}"`}
            />
          </div>
        </div>

        {formInputs.map((formInput) => (
          <TextInput key={formInput.name} {...formInput} />
        ))}

        <Row>
          <Col className="mr-3 pr-0">
            <TextInput name="postal" placeholder="Postal Code *" />
          </Col>

          <Col className="pl-0">
            <TextInput name="fax" placeholder="Fax" />
          </Col>
        </Row>

        <CourtSelect
          residence={residence}
          label="Default Court (for documents)"
          containerClassName="mb-3"
          isFormikField
          provinceShorthand={regionShorthand}
          onChange={({ value }) => {
            setFieldValue("courtId", value);
            setFieldValue("courtLocationId", null);
          }}
        />

        <CourtLocationSelect
          courtId={courtId}
          residence={residence}
          provinceShorthand={regionShorthand}
          label="Default Court Location (for documents)"
          containerClassName="mb-3"
          isFormikField
        />

        <HttpInput
          containerClassName="mb-3"
          name="googleReviewLink"
          placeholder="Google My Business Review link"
        />

        <div className="d-flex justify-content-between align-items-end buttons">
          <Button className="-mb-1" type="submit" size="lg" disabled={loadingUpdateOrg}>
            Save & Submit
          </Button>

          {!defaultLocation && type === "EDIT" && (
            <Button color="red-link" leftIcon="trash" onClick={remove}>
              Delete
            </Button>
          )}
        </div>
      </Form>
    </FormikProvider>
  );
};
export default LocationForm;
