import React, { useMemo } from "react";

import dayjs from "dayjs";
import { range } from "lodash";

import Select from "./Select";
import SelectField from "./SelectField";

const taxYears = range(2017, dayjs().year() + 1).reverse();

/**
 * @property {string} name - By default "taxYear", can be changed
 * @property {boolean} isFormikField - By default "false", responsible for what to use "SelectField" or "Select"
 */
const TaxYearSelect = ({
  name = "taxYear",

  isFormikField = false,

  ...props
}) => {
  const options = useMemo(() => {
    return taxYears
      .map((value) => ({
        label: new Date().getFullYear() === value ? `${value} (current)` : value,
        value: value.toString(),
      }))
      .concat({
        label: "More years coming soon...",
        value: null,
        isDisabled: true,
      });
  }, []);

  const selectProps = useMemo(() => {
    return {
      name,
      options,
      ...props,
    };
  }, [name, options, props]);

  if (isFormikField) {
    return <SelectField {...selectProps} />;
  }

  return <Select {...selectProps} />;
};

export default TaxYearSelect;
