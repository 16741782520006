import gql from "graphql-tag";

const ORGANIZATION_LOCATION = gql`
  fragment ORGANIZATION_LOCATION on OrganizationLocation {
    id
    phone
    residence
    street1
    street2
    city
    email
    postal
    website
    fax
    googleReviewLink
    courtId
    courtLocationId
    swearingCity
  }
`;

export default ORGANIZATION_LOCATION;
