export const DOMAIN = "localhost";

export const trafficType =
  window.location.hostname === "www.divorcepath.com" ? "external" : "internal";

export const addInLink =
  "https://appsource.microsoft.com/en-us/product/office/wa200005568?tab=overview";

export const DONT_SHOW_ONBOARDING_KEY = "dontShowOnboarding";

export const CALCULATOR_ROUTES = ["spousal-support", "child-support"];

export const TRAINING_VIDEOS_LINK =
  "https://www.youtube.com/playlist?list=PLfFBMnvlapyK6op317Anm1c3wS-ZPmp7W";
