import React, { useCallback } from "react";

import { useFormikContext } from "formik";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";

import Button from "components/common/Button";

import assignObjectValuesToSet from "utils/assignObjectValuesToSet";

const SubmitChangesCard = ({
  clientLawyerData,
  setClientLawyerData,
  setSharedLawyerBaseline,
  computeUpdatedLawyer,
  computeInitialValues,
  hasLawyerChanges,
  updateLawyer,
  createLawyer,
  updateLoading,
}) => {
  const { values, resetForm } = useFormikContext();

  const handleSubmitChanges = useCallback(async () => {
    const { location, name, orgName, sanityId } = values;

    const isUpdate = clientLawyerData?.sanityId === sanityId;
    const func = isUpdate ? updateLawyer : createLawyer;
    const mutationData = {
      ...(isUpdate
        ? assignObjectValuesToSet({ name, orgName, sanityId })
        : { name, orgName, sanityId }),
      location: isUpdate
        ? { create: location, update: assignObjectValuesToSet(location) }
        : { create: location },
    };
    try {
      const response = await func(mutationData);
      const updatedLawyer = response?.data?.updateLawyer || response?.data?.createLawyer;
      if (updatedLawyer) {
        setClientLawyerData(updatedLawyer);
        setSharedLawyerBaseline(cloneDeep(updatedLawyer));
        const newValues = computeInitialValues(updatedLawyer);
        resetForm({ values: newValues });
      } else {
        const fallback = computeUpdatedLawyer(values);
        setClientLawyerData(fallback);
        setSharedLawyerBaseline(cloneDeep(fallback));
      }
    } catch (error) {
      if (error.graphQLErrors) error.graphQLErrors.forEach(({ message }) => toast.error(message));
    }
  }, [
    clientLawyerData?.sanityId,
    createLawyer,
    updateLawyer,
    computeInitialValues,
    computeUpdatedLawyer,
    resetForm,
    values,
    setClientLawyerData,
    setSharedLawyerBaseline,
  ]);

  if (values.isSelfRepresented || !hasLawyerChanges) return null;

  return (
    <div className="info-tip update-lawyer-card mb-3">
      <p>
        You've changed the lawyer profile information. Submit these changes to the Divorcepath
        lawyer database?
      </p>
      <div className="actions" style={{ display: "flex", gap: "0.5rem" }}>
        <Button onClick={handleSubmitChanges} disabled={updateLoading}>
          Update Database
        </Button>
        <Button color="secondary" disabled={updateLoading}>
          No
        </Button>
      </div>
    </div>
  );
};

export default SubmitChangesCard;
