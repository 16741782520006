import React from "react";

import TagManager from "react-gtm-module";
import { ToastContainer, Slide } from "react-toastify";

import Routes from "layout/Routes/Routes";

import MainErrorBoundary from "components/MainErrorBoundary";
import ScrollToTop from "components/common/ScrollToTop";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

const tagManagerArgs = {
  gtmId: "GTM-PVTMCSH",
};

TagManager.initialize(tagManagerArgs);

library.add(fas);

const App = () => {
  return (
    <MainErrorBoundary>
      <React.Fragment>
        <Routes />
        <ToastContainer
          position="top-right"
          transition={Slide}
          autoClose={3000}
          newestOnTop={false}
          rtl={false}
          limit={1}
          hideProgressBar
          closeOnClick
          draggable
          pauseOnHover
          autoFocus={false}
          aria-live="polite"
        />
        <ScrollToTop />
      </React.Fragment>
    </MainErrorBoundary>
  );
};

export default App;
