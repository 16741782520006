import React, { useCallback, useMemo } from "react";

import { useFormikContext } from "formik";
import { cloneDeep } from "lodash";

import CreatableSelect from "components/common/inputs/Select/CreatableSelect";

import assignObjectValuesToSet from "utils/assignObjectValuesToSet";

import LawyerSelectOption from "./LawyerSelectOption";

const CreateLawyerSelect = ({
  user,
  refetch,
  clientLawyerData,
  data,
  setClientLawyerData,
  setSharedLawyerBaseline,
  computeInitialValues,
  createLawyer,
  updateLawyer,
  clientLoading,
  createLoading,
  updateLoading,
}) => {
  const { setFieldValue, setValues, values } = useFormikContext();

  const { sanityId } = values;

  const handleCreateLawyer = async (name) => {
    const createdLawyer = await createLawyer({ name });

    const location = { residence: user?.client?.court?.provinceShorthand };

    /*
      Temporary solution because it is currently impossible
      to assign a location to a lawyer in Sanity via createLawyer

      Awaiting a fix from the API side
    */
    await updateLawyer(
      {
        name: { set: name },
        sanityId: { set: createdLawyer?.createLawyer?.sanityId },
        location: { create: location, update: assignObjectValuesToSet(location) },
      },
      false,
    );

    await refetch();
  };

  const handleChange = useCallback(
    (selectedOption) => {
      setFieldValue("sanityId", selectedOption.value);
      const selectedLawyer = data?.allLawyer?.find(
        (lawyer) => lawyer?._id === selectedOption.value,
      );
      if (selectedLawyer) {
        setSharedLawyerBaseline(cloneDeep(selectedLawyer));
        const newValues = computeInitialValues(selectedLawyer);
        setValues(newValues);
        setClientLawyerData(selectedLawyer);
      }
    },
    [
      computeInitialValues,
      setSharedLawyerBaseline,
      data?.allLawyer,
      setValues,
      setClientLawyerData,
      setFieldValue,
    ],
  );

  const selectOptions = useMemo(() => {
    return (
      data?.allLawyer?.map((lawyer) => ({
        label: lawyer?.name,
        value: lawyer?._id,
        city: lawyer?.city,
        orgName: lawyer?.orgName,
      })) || []
    );
  }, [data?.allLawyer]);

  return (
    <CreatableSelect
      label="Name"
      placeholder="Select or Create a Lawyer"
      containerClassName="mb-3"
      key="sanityId"
      name="sanityId"
      value={sanityId ? { value: sanityId, label: clientLawyerData?.name } : null}
      handleChange={handleChange}
      onCreate={handleCreateLawyer}
      disabled={clientLoading || createLoading || updateLoading}
      options={selectOptions}
      components={{ Option: LawyerSelectOption }}
    />
  );
};

export default CreateLawyerSelect;
