import { useEffect, useRef, useMemo } from "react";

import { useFormikContext } from "formik";
import isEqual from "lodash.isequal";
import { useDebounce } from "use-debounce";

const AutoSave = ({ excludeFields = [], debounceMS = 2000 }) => {
  const { values, submitForm, isSubmitting } = useFormikContext();

  const stableExcludeFields = useRef(excludeFields);

  // Create a filtered copy of values based on the fixed excludeFields.
  const filteredValues = useMemo(() => {
    const copy = { ...values };
    stableExcludeFields.current.forEach((field) => {
      delete copy[field];
    });
    return copy;
  }, [values]);

  const [debouncedValues] = useDebounce(filteredValues, debounceMS);

  const isInitialMount = useRef(true);
  const lastSubmittedValues = useRef(debouncedValues);
  const latestValues = useRef(values);

  useEffect(() => {
    latestValues.current = values;
  }, [values]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      lastSubmittedValues.current = debouncedValues;
      return;
    }

    // Check if the currently focused element is an input (or textarea/select). If so, skip autosave.
    const { tagName } = document.activeElement || {};
    const skipTags = ["INPUT", "TEXTAREA", "SELECT"];
    if (tagName && skipTags.includes(tagName)) {
      return;
    }

    if (!isEqual(debouncedValues, lastSubmittedValues.current)) {
      if (!isSubmitting) {
        submitForm()
          .then(() => {
            lastSubmittedValues.current = debouncedValues;
          })
          .catch((err) => {
            console.error("AutoSave submission error:", err);
          });
      }
    }
  }, [debouncedValues, submitForm, isSubmitting]);

  useEffect(() => {
    const initialExcludeFields = stableExcludeFields.current;
    return () => {
      const finalFilteredValues = { ...latestValues.current };
      initialExcludeFields.forEach((field) => {
        delete finalFilteredValues[field];
      });
      if (!isEqual(finalFilteredValues, lastSubmittedValues.current)) {
        submitForm();
      }
    };
  }, [submitForm]);

  return null;
};

export default AutoSave;
